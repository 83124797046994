import api from '~/api';
import { Fingerprint } from '~/classes/Fingerprint';
import { Gib } from '~/classes/Gib';
import actionCreator from '~/utils/actionCreator';

const gib = new Gib();

const { createAction, createAsyncAction } = actionCreator('src/models/payment');

export const ACTIONS = {
  getStatus: createAsyncAction('GET_STATUS'),
  init: createAction('INIT'),
  pay: createAsyncAction('PAY'),
};

export const TYPES = {
  pay: 'Pay',
};

const initialState = {
  publicToken: null,
  type: null,

  error: null,
  isProcessed: false,
  result: null,
};

export default (store) => {
  store.on('@init', () => ({
    payment: initialState,
  }));

  store.on(
    ACTIONS.init,
    ({ payment }, { publicToken, sessionId, type = TYPES.pay }) => {
      gib.setSessionID(sessionId);

      return {
        payment: {
          ...payment,
          publicToken,
          type,
        },
      };
    },
  );

  store.on(ACTIONS.pay.TRIGGER, ({ payment }, payload) => {
    const {
      values: {
        infoCardNumber: { masked_card_number: maskedCardNumber },
        ...restValues
      },
      ...restPayload
    } = payload;

    store.dispatch(ACTIONS.pay.REQUEST, {
      ...restPayload,
      values: restValues,
    });

    gib.setAttribute('masked_sender_card', maskedCardNumber);
    gib.setIdentity(maskedCardNumber);

    return {
      payment: {
        ...payment,
        isProcessed: true,
      },
    };
  });

  store.on(ACTIONS.pay.REQUEST, async ({ payment }, payload) => {
    const { publicToken } = payment;
    const { gatherFingerprint, sessionId, successOnHold, values } = payload;

    const meta = Gib.isRequired ? { facctSessionId: sessionId } : null;

    try {
      if (gatherFingerprint) {
        const fp = new Fingerprint();

        fp.init();
      }

      const response = await api.payment.pay({
        ...values,
        meta,
        publicToken,
      });
      const { status } = response;

      if (status === 'ok') {
        store.dispatch(ACTIONS.pay.SUCCESS, { successOnHold });
      } else {
        const { error: { description: error } = {} } = response;

        store.dispatch(ACTIONS.pay.FAILURE, error);
      }
    } catch (error) {
      const message = error?.message ?? 'unknown';

      store.dispatch(ACTIONS.pay.FAILURE, message);
    }
  });

  store.on(ACTIONS.pay.SUCCESS, (_, payload) => {
    store.dispatch(ACTIONS.getStatus.TRIGGER, payload);
  });

  store.on(ACTIONS.pay.FAILURE, ({ payment }, error) => ({
    payment: {
      ...payment,
      error,
      isProcessed: false,
      result: initialState.result,
    },
  }));

  store.on(ACTIONS.getStatus.TRIGGER, (_, payload) => {
    store.dispatch(ACTIONS.getStatus.REQUEST, payload);
  });

  store.on(
    ACTIONS.getStatus.REQUEST,
    async ({ payment }, { successOnHold }) => {
      const { publicToken } = payment;

      try {
        const transaction = await api.payment.getStatusByToken(publicToken, {
          successOnHold,
        });

        store.dispatch(ACTIONS.getStatus.SUCCESS, transaction);
      } catch (error) {
        store.dispatch(ACTIONS.getStatus.FAILURE, error);
      }
    },
  );

  store.on(ACTIONS.getStatus.SUCCESS, ({ payment }, transaction) => ({
    payment: {
      ...payment,
      error: initialState.error,
      isProcessed: false,
      result: transaction,
    },
  }));

  store.on(ACTIONS.getStatus.FAILURE, ({ payment }, error) => ({
    payment: {
      ...payment,
      error,
      isProcessed: false,
    },
  }));
};
