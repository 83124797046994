import { h } from 'preact';

import api from '~/api';
import { InputCardholder } from '~/components/InputCardholder';

import createTokenizer from './createTokenizer';

const CARD_ELEMENT_TYPE = 'cardholder';

const { Consumer, path, Provider } = createTokenizer({
  provider: {
    createToken: ({ name, publicToken, value }) =>
      api.token.create({
        name,
        publicToken,
        type: CARD_ELEMENT_TYPE,
        value,
      }),
    render(props) {
      return <InputCardholder {...props} withDeco={false} />;
    },
  },
  slug: CARD_ELEMENT_TYPE,
});

export { Consumer as default, path, Provider };
