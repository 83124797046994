import { h } from 'preact';
import { forwardRef } from 'preact/compat';

import { InputBase } from '~/components/InputBase';
import { InputMasked } from '~/components/InputMasked';

export const InputCardholder = forwardRef((props, ref) => {
  const {
    'aria-label': ariaLabel = 'Имя и фамилия держателя',
    mask = 'cardholder',
    placeholder = 'ИМЯ ФАМИЛИЯ',
    ...restProps
  } = props;

  return (
    <InputBase
      {...restProps}
      aria-label={ariaLabel}
      mask={mask}
      placeholder={placeholder}
      render={InputMasked}
      autoComplete="cc-name"
      autoCorrect="off"
      ref={ref}
      spellCheck="false"
      type="text"
    />
  );
});
