/**
 * Редирект страницы на заданный URL.
 *
 * @param {string} url - URL.
 * @param {Object} [options={}] - Объект дополнительных настроек.
 * @param {'parent'|'self'|'top'} [options.target='top'] - Целевое окно.
 * @returns {undefined}
 */
export function redirect(url, options = {}) {
  const { target = 'top' } = options;
  const {
    [target]: { location },
  } = window;

  location.href = url;
}
