import { loadScript } from '~/utils/loadScript';

const SCRIPT_URL = 'https://fp-gw.com/static/fp.js';

export class Fingerprint {
  static instance = null;

  constructor(params = {}) {
    if (Fingerprint.instance) {
      return Fingerprint.instance;
    }

    const { scriptUrl = SCRIPT_URL } = params;

    this.scriptUrl = scriptUrl;
  }

  async init(payload) {
    if (process.env.FINGERPRINT_IS_ENABLED !== 'true') {
      return;
    }

    await loadScript(this.scriptUrl, { maxAttemptsCount: 3 });

    return window.initUserFingerprint(payload);
  }
}
