export const loadScript = (src, options = {}) => {
  const { maxAttemptsCount = 1 } = options;

  return new Promise((resolve, reject) => {
    const createScript = (attempt = 1) => {
      const script = document.createElement('script');

      script.src = src;
      script.defer = true;

      const handleLoading = () => {
        resolve();
      };
      const handleError = () => {
        if (attempt >= maxAttemptsCount) {
          reject();

          return null;
        }

        script.removeEventListener('error', handleError);
        script.removeEventListener('load', handleLoading);
        script.remove();
        createScript(attempt + 1);
      };

      script.addEventListener('error', handleError);
      script.addEventListener('load', handleLoading);

      document.body.appendChild(script);
    };

    createScript();
  });
};
