import { h } from 'preact';
import { memo } from 'preact/compat';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useStoreon } from 'storeon/preact';

import MainContainer from '~/containers/MainContainer';
import WidgetBase, { TYPES as WIDGET_TYPES } from '~/containers/WidgetBase';
import useDidMount from '~/hooks/useDidMount';
import { ACTIONS, TYPES } from '~/models/payment';

import Stage from './components/Stage';

const PaymentContainer = (props) => {
  const {
    isCvcMasked,
    publicToken,
    customerInteractionRedirect,
    texts,

    onPaymentFail,
    onReady,
    onPaymentStart,
    onPaymentSuccess,
  } = props;

  const { payment, dispatch } = useStoreon('payment');

  const [sessionId, setSessionId] = useState(null);

  const handlePay = useCallback(
    (payload) => {
      dispatch(ACTIONS.pay.TRIGGER, payload);
      onPaymentStart();
    },
    [dispatch, onPaymentStart],
  );

  useDidMount(() => {
    onReady();
  });

  useEffect(() => {
    if (!sessionId) {
      return;
    }

    dispatch(ACTIONS.init, {
      publicToken,
      sessionId,
      type: TYPES.pay,
    });
  }, [dispatch, publicToken, sessionId]);

  return (
    <WidgetBase
      publicToken={publicToken}
      render={({ extraData }) => {
        setSessionId(extraData.session_id);

        return (
          <MainContainer locale={extraData?.locale}>
            <Stage
              customerInteractionRedirect={customerInteractionRedirect}
              extraData={extraData}
              isCvcMasked={isCvcMasked}
              payment={payment}
              publicToken={publicToken}
              onPay={handlePay}
              onPaymentFail={onPaymentFail}
              onPaymentSuccess={onPaymentSuccess}
              texts={texts}
            />
          </MainContainer>
        );
      }}
      type={WIDGET_TYPES.acquiring}
    />
  );
};

export default memo(PaymentContainer);
