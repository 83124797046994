const PREFIX = 'provider_';
const UNIQUE_ERROR = '3DS_error';

export const mapPaymentErrorByCode = (code) => {
  if (code === `${PREFIX}3ds_error` || code === '3ds_error') {
    return UNIQUE_ERROR;
  }

  if (code.startsWith(PREFIX)) {
    return code.substring(PREFIX.length);
  }

  return code;
};
