import { NodeType } from './constants';
import { last } from './last';

export const update = (path, setValue, list = []) => {
  const [levelAlias, ...restPath] = path;

  if (!levelAlias) {
    setValue(list);
  } else {
    const level =
      list.find(
        ({ alias, type }) => type === NodeType.Block && alias === levelAlias,
      ) ||
      (() => {
        list.push({
          alias: levelAlias,
          children: [],
          type: NodeType.Block,
        });

        return last(list);
      })();

    update(restPath, setValue, level.children);
  }
};
