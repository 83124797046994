import { NodeType } from './constants';

export const toArray = (level, values) => {
  return level.reduce((result, item) => {
    if (item.type === NodeType.Block) {
      const { [item.alias]: render = (content) => content } = values;

      return [...result, render(toArray(item.children, values))];
    }

    if (item.type === NodeType.Text) {
      return [...result, item.children];
    }

    if (item.type === NodeType.Placeholder) {
      const { [item.alias]: value = '' } = values;

      return [...result, value];
    }

    return result;
  }, []);
};
