import { h } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';

import { Link } from '~/components/Link';
import { interpolate } from '~/utils/interpolate';

const AUTO_REDIRECTION_DELAY = 3;
const ONE_SECOND = 1000;

const CustomerInteraction = (props) => {
  const {
    customerInteractionRedirect = {},
    texts = {},
    type,
    [type]: interaction,
  } = props;

  const { target } = {
    target: '_top',
    ...customerInteractionRedirect,
  };

  const {
    base_url: baseUrl,
    method = 'POST',
    params = {},
    qs = {},
    url,
  } = interaction;
  const redirectUrl = method === 'GET' ? baseUrl : url;
  const passedParams = method === 'GET' ? qs : params;
  const hiddenInputs = useMemo(
    () =>
      Object.entries(passedParams).map(([name, value]) => (
        <input key={name} name={name} type="hidden" value={value} />
      )),
    [passedParams],
  );
  const [countdown, setCountdown] = useState(AUTO_REDIRECTION_DELAY);
  const buttonRef = useRef();

  useEffect(() => {
    const newCountdown = countdown - 1;

    if (newCountdown >= 0) {
      setTimeout(() => setCountdown(newCountdown), ONE_SECOND);
    } else {
      buttonRef.current.click();
    }
  }, [countdown]);

  return (
    <form action={redirectUrl} method={method} target={target}>
      <p>
        {interpolate(texts.redirectionScreen.waitForRedirectToBanksPage, {
          countdown,
          strong: (content) => <strong>{content}</strong>,
        })}
      </p>

      <p>
        {interpolate(texts.redirectionScreen.followTheLink, {
          link: (content) => (
            <Link ref={buttonRef} type="submit">
              {content}
            </Link>
          ),
        })}
      </p>

      {hiddenInputs}
    </form>
  );
};

export default CustomerInteraction;
