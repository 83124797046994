import { h } from 'preact';
import { useCallback, useMemo } from 'preact/hooks';

import { PaymentInfo } from '~/components/PaymentInfo';
import { PaymentStatus } from '~/components/PaymentStatus';
import { transactionStatuses } from '~/constants/transactionStatuses';
import Tokenizer from '~/containers/Tokenizer';
import mergeObjects from '~/utils/mergeObjects';
import { redirect } from '~/utils/redirect';

import PaymentForm from '../PaymentForm';
import CustomerInteraction from '../CustomerInteraction';
import { useTranslations } from '../../i18n/useTranslations';
import { mapPaymentErrorByCode } from '../../utils/mapPaymentErrorByCode';

const Stage = (props) => {
  const {
    customerInteractionRedirect,
    extraData: {
      failure_return_url: failureUrl,
      gather_fingerprint: gatherFingerprint = false,
      locale = process.env.DEFAULT_LANG,
      session_id: sessionId,
      show_recurrent_checkbox: showRecurrentCheckbox,
      success_on_hold: successOnHold = false,
      success_return_url: successUrl,
    } = {},
    isCvcMasked,
    payment: { error, isProcessed, result },
    publicToken,
    texts,

    onPay,
    onPaymentFail,
    onPaymentSuccess,
  } = props;

  const { i18n } = useTranslations(locale);

  const handlePay = useCallback(
    (values) => {
      onPay({ gatherFingerprint, sessionId, successOnHold, values });
    },
    [gatherFingerprint, onPay, sessionId, successOnHold],
  );

  const resultTexts = useMemo(() => {
    if (i18n) {
      return mergeObjects(i18n, texts ?? {});
    }

    return null;
  }, [i18n, texts]);

  if (!resultTexts) {
    return null;
  }

  if (isProcessed) {
    return (
      <PaymentStatus
        subtitle={resultTexts.paymentProcessScreen.title}
        type="process"
      >
        {resultTexts.paymentProcessScreen.description}
      </PaymentStatus>
    );
  }

  if (error) {
    onPaymentFail(error);

    return (
      <PaymentStatus
        error={error === 'unknown' ? resultTexts.unknownError : error}
        subtitle={resultTexts.failedPaymentScreen.title}
        type="failure"
      />
    );
  }

  if (result) {
    const { customer_interaction: customerInteraction, status } = result;

    if (status === transactionStatuses.pending && customerInteraction) {
      return (
        <PaymentStatus
          subtitle={resultTexts.redirectionScreen.title}
          type="customer_interaction"
        >
          <CustomerInteraction
            {...customerInteraction}
            customerInteractionRedirect={customerInteractionRedirect}
            texts={resultTexts}
          />
        </PaymentStatus>
      );
    }

    if (status === transactionStatuses.failed) {
      if (failureUrl) {
        redirect(failureUrl);

        return (
          <PaymentStatus
            subtitle={resultTexts.paymentProcessScreen.title}
            type="process"
          >
            {resultTexts.paymentProcessScreen.description}
          </PaymentStatus>
        );
      }

      onPaymentFail(error);

      const {
        error: { code },
      } = result;

      const paymentError =
        resultTexts.failedPaymentScreen.description[
          mapPaymentErrorByCode(code)
        ] ?? resultTexts.unknownError;

      return (
        <PaymentStatus
          error={paymentError}
          subtitle={resultTexts.failedPaymentScreen.title}
          type="failure"
        />
      );
    }

    const {
      amount_details: amountDetails,
      id: transactionId,
      payment_details: paymentDetails,
    } = result;

    if (
      status === transactionStatuses.succeeded ||
      status === transactionStatuses.pending
    ) {
      onPaymentSuccess();

      if (successUrl) {
        redirect(successUrl);

        return (
          <PaymentStatus
            subtitle={resultTexts.paymentProcessScreen.title}
            type="process"
          >
            {resultTexts.paymentProcessScreen.description}
          </PaymentStatus>
        );
      }

      return (
        <PaymentStatus
          subtitle={resultTexts.successPaymentScreen.title}
          type="success"
        >
          <PaymentInfo
            amountDetails={amountDetails}
            paymentDetails={paymentDetails}
            texts={resultTexts}
            transactionId={transactionId}
          />
        </PaymentStatus>
      );
    }

    if (failureUrl) {
      redirect(failureUrl);

      return (
        <PaymentStatus
          subtitle={resultTexts.paymentProcessScreen.title}
          type="process"
        >
          {resultTexts.paymentProcessScreen.description}
        </PaymentStatus>
      );
    }

    onPaymentFail();

    return (
      <PaymentStatus
        error={resultTexts.unknownError}
        subtitle={resultTexts.failedPaymentScreen.title}
        type="failure"
      >
        <PaymentInfo
          amountDetails={amountDetails}
          paymentDetails={paymentDetails}
          texts={resultTexts}
          transactionId={transactionId}
        />
      </PaymentStatus>
    );
  }

  return (
    <Tokenizer publicToken={publicToken}>
      <PaymentForm
        isCvcMasked={isCvcMasked}
        onSubmit={handlePay}
        showRecurrentCheckbox={showRecurrentCheckbox}
        texts={resultTexts}
      />
    </Tokenizer>
  );
};

export default Stage;
