import classNames from 'classnames';
import { h } from 'preact';
import { useState } from 'preact/compat';

import generateId from '~/utils/generateId';

import * as styles from './styles.css';

export const Checkbox = (props) => {
  const {
    children,
    id: passedId,
    isInvalid,
    name,
    value,
    withLabel = true,

    onChange,
    onInput = onChange,

    ...rest
  } = props;
  const [id] = useState(() => passedId ?? generateId());
  const Container = withLabel ? 'label' : 'span';
  const Icon = withLabel ? 'span' : 'label';

  const handleChange = ({ target: { checked } }) => {
    return onInput(checked);
  };

  return (
    <Container
      className={classNames(
        styles.container,
        isInvalid ? styles.isInvalid : null,
      )}
    >
      <input
        {...rest}
        className={styles.input}
        id={id}
        name={name}
        onChange={handleChange}
        type="checkbox"
        value={value}
      />

      <Icon className={styles.iconContainer} htmlFor={id} role="img">
        <svg
          className={styles.icon}
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3}
          viewBox="0 0 15 11"
          width={11}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 6L5 10L14 1" />
        </svg>
      </Icon>

      {children}
    </Container>
  );
};
