import { h } from 'preact';

import { formatAmount } from '~/utils/formatData';

import * as styles from './styles.css';

export const PaymentInfo = (props) => {
  const {
    amountDetails: { amount, currency },
    paymentDetails: { card } = {},
    texts = {},
    transactionId,
  } = props;

  return (
    <dl className={styles.details}>
      <div className={styles.detailsRow}>
        <dt>
          {texts.transactionData.creditCardLabel} {card.brand}
        </dt>
        <dd>*{card.last4}</dd>
      </div>

      <div className={styles.detailsRow}>
        <dt>{texts.transactionData.amountLabel}</dt>
        <dd>{formatAmount(amount, { currency })}</dd>
      </div>

      <div className={styles.detailsRow}>
        <dt>{texts.transactionData.transactionIdLabel}</dt>
        <dd>{transactionId}</dd>
      </div>
    </dl>
  );
};
