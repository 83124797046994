// extracted by mini-css-extract-plugin
var _1 = "bank131-PaymentStatus__container";
var _2 = "bank131-PaymentStatus__description";
var _3 = "bank131-PaymentStatus__failureIcon bank131-PaymentStatus__icon";
var _4 = "bank131-PaymentStatus__icon";
var _5 = "bank131-PaymentStatus__isFailure";
var _6 = "bank131-PaymentStatus__isSuccess";
var _7 = "bank131-PaymentStatus__loader";
var _8 = "bank131-PaymentStatus__loaderContainer";
var _9 = "bank131-PaymentStatus__spin";
var _a = "bank131-PaymentStatus__successIcon bank131-PaymentStatus__icon";
var _b = "bank131-PaymentStatus__title";
export { _1 as "container", _2 as "description", _3 as "failureIcon", _4 as "icon", _5 as "isFailure", _6 as "isSuccess", _7 as "loader", _8 as "loaderContainer", _9 as "spin", _a as "successIcon", _b as "title" }
