import { NodeType } from './constants';
import { last } from './last';
import { update } from './update';

export const parse = (template) => {
  const result = [];
  const matches = template.matchAll(/{{[/#]?\s*([a-z]\w*)\s*}}/gi);
  const levels = [];

  let position = 0;

  for (let value of matches) {
    // Вычисление подстроки с прошлого токена до текущего.
    const textNode = template.substring(position, value.index);

    if (textNode) {
      update(
        levels,
        (prevValue) =>
          prevValue.push({ children: textNode, type: NodeType.Text }),
        result,
      );
    }

    const [token, alias] = value;

    if (/^{{#/.test(token)) {
      // Начало блока
      levels.push(alias);
    } else if (/^{{\//.test(token)) {
      // Конец блока
      if (alias !== last(levels)) {
        throw new Error(
          `Expected level "${last(
            levels,
          )}" to be closed but "${alias}" (${token}) passed.\n\n${levels}`,
        );
      }

      levels.pop();
    } else {
      // Подстановка
      update(
        levels,
        (prevValue = []) =>
          prevValue.push({
            alias,
            type: NodeType.Placeholder,
          }),
        result,
      );
    }

    position = value.index + token.length;
  }

  // Финальная подстрока.
  const textNode = template.substring(position);

  if (textNode) {
    update(
      levels,
      (prevValue) =>
        prevValue.push({
          children: textNode,
          type: NodeType.Text,
        }),
      result,
    );
  }

  return result;
};
