const creator = (prefix) => (name) =>
  `${process.env.PREFIX}/${prefix}/${name.toUpperCase()}`;

export default (prefix) => {
  return {
    createAction: creator(prefix),
    createAsyncAction: (name) => ({
      TRIGGER: creator(prefix)(`${name}_TRIGGER`),
      REQUEST: creator(prefix)(`${name}_REQUEST`),
      SUCCESS: creator(prefix)(`${name}_SUCCESS`),
      FAILURE: creator(prefix)(`${name}_FAILURE`),
    }),
  };
};
