import classNames from 'classnames';
import { h } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { Field, Form } from 'react-final-form';

import { Button } from '~/components/Button';
import { Checkbox } from '~/components/Checkbox';
import { Field as FormField } from '~/components/Field';
import { Link } from '~/components/Link';
import { useTokenizer } from '~/containers/Tokenizer';
import TokenizerCardNumber from '~/containers/Tokenizer/TokenizerCardNumber';
import TokenizerCardholder from '~/containers/Tokenizer/TokenizerCardholder';
import TokenizerCvv from '~/containers/Tokenizer/TokenizerCvv';
import TokenizerExpiryDate from '~/containers/Tokenizer/TokenizerExpiryDate';
import { interpolate } from '~/utils/interpolate';
import mergeObjects from '~/utils/mergeObjects';

import MasterCardIcon from './images/mc_idcheck.svg';
import MirIcon from './images/mir_accept.svg';
import VisaIcon from './images/visa_secure.svg';
import * as styles from './styles.css';

const FIELD_NAMES = {
  cardholder: 'cardholder',
  cardNumber: 'cardNumber',
  cvv: 'cvv',
  expireDate: 'expireDate',
  recurrent: 'recurrent',
};

const logosOf3dsSystems = [
  ...(process.env.MIR_3DS_SYSTEM_IS_ENABLED === 'yes'
    ? [
        {
          name: 'Mir Accept',
          src: MirIcon,
        },
      ]
    : []),
  ...(process.env.VISA_3DS_SYSTEM_IS_ENABLED === 'yes'
    ? [
        {
          name: 'Visa Secure',
          src: VisaIcon,
        },
      ]
    : []),
  ...(process.env.MASTERCARD_3DS_SYSTEM_IS_ENABLED === 'yes'
    ? [
        {
          name: 'ID Check',
          src: MasterCardIcon,
        },
      ]
    : []),
];

const termsUrl = process.env.SITE_TERMS_URL;

const PaymentForm = (props) => {
  const {
    isCvcMasked = false,
    showRecurrentCheckbox = false,
    onSubmit,
    texts = {},
  } = props;

  const mapErrors = useCallback(
    (code) => {
      return (
        mergeObjects(
          {
            INVALID_CARD_NUMBER: texts.validationErrors.invalidCardNumber,
            INVALID_CVV: texts.validationErrors.invalidCvv,
            INVALID_EXPIRY_DATE: texts.validationErrors.invalidExpiryDate,
            IS_REQUIRED: texts.validationErrors.isRequired,
          },
          texts.validationErrors,
        )[code] || code
      );
    },
    [texts.validationErrors],
  );

  const tokenizer = useTokenizer();
  const [errors, setErrors] = useState({
    [FIELD_NAMES.cardholder]: null,
    [FIELD_NAMES.cardNumber]: null,
    [FIELD_NAMES.cvv]: null,
    [FIELD_NAMES.expireDate]: null,
  });
  const handlePay = useCallback(
    async (values) => {
      const tokenizationResult = await tokenizer.tokenizeAll([
        FIELD_NAMES.cardholder,
        FIELD_NAMES.cardNumber,
        FIELD_NAMES.cvv,
        FIELD_NAMES.expireDate,
      ]);
      const hasError = tokenizationResult.some(
        ({ status }) => status === 'error',
      );

      if (hasError) {
        return;
      }

      const [
        { token: tokenCardholder },
        { info: infoCardNumber, token: tokenCardNumber },
        { token: tokenCvv },
        { token: tokenExpiryDate },
      ] = tokenizationResult;

      onSubmit({
        ...values,
        infoCardNumber,
        tokenCardholder,
        tokenCardNumber,
        tokenCvv,
        tokenExpiryDate,
      });
    },
    [tokenizer, onSubmit],
  );

  const handleInvalid = useCallback(
    (code, name) =>
      setErrors((restErrors) => ({
        ...restErrors,
        [name]: mapErrors(code),
      })),
    [mapErrors],
  );
  const handleValid = useCallback(
    (name) =>
      setErrors((restErrors) => ({
        ...restErrors,
        [name]: null,
      })),
    [],
  );

  return (
    <Form
      initialValues={{ [FIELD_NAMES.recurrent]: false }}
      onSubmit={handlePay}
    >
      {({ submitting, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.col}>
                <FormField
                  component={TokenizerCardNumber}
                  error={errors[FIELD_NAMES.cardNumber]}
                  label={texts.paymentForm.cardNumberLabel}
                  name={FIELD_NAMES.cardNumber}
                  note={texts.paymentForm.cardNumberNote}
                  onInvalid={handleInvalid}
                  onSubmit={handleSubmit}
                  onValid={handleValid}
                  placeholder={texts.paymentForm.cardNumberPlaceholder}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.col}>
                <FormField
                  component={TokenizerExpiryDate}
                  error={errors[FIELD_NAMES.expireDate]}
                  label={texts.paymentForm.expireDateLabel}
                  name={FIELD_NAMES.expireDate}
                  note={texts.paymentForm.expireDateNote}
                  onInvalid={handleInvalid}
                  onSubmit={handleSubmit}
                  onValid={handleValid}
                  placeholder={texts.paymentForm.expireDatePlaceholder}
                />
              </div>

              <div className={styles.col}>
                <FormField
                  component={TokenizerCvv}
                  error={errors[FIELD_NAMES.cvv]}
                  label={texts.paymentForm.cvvLabel}
                  name={FIELD_NAMES.cvv}
                  note={texts.paymentForm.cvvNote}
                  onInvalid={handleInvalid}
                  onSubmit={handleSubmit}
                  onValid={handleValid}
                  placeholder={texts.paymentForm.cvvPlaceholder}
                  type={isCvcMasked ? 'password' : undefined}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.col}>
                <FormField
                  component={TokenizerCardholder}
                  error={errors[FIELD_NAMES.cardholder]}
                  label={texts.paymentForm.cardholderLabel}
                  name={FIELD_NAMES.cardholder}
                  note={texts.paymentForm.cardholderNote}
                  onInvalid={handleInvalid}
                  onSubmit={handleSubmit}
                  onValid={handleValid}
                  placeholder={texts.paymentForm.cardholderPlaceholder}
                />
              </div>
            </div>

            {showRecurrentCheckbox && (
              <div className={styles.row}>
                <div className={styles.col}>
                  <Field
                    name={FIELD_NAMES.recurrent}
                    render={({ input }) => {
                      return (
                        <Checkbox {...input}>
                          {texts.paymentForm.recurrentLabel}
                        </Checkbox>
                      );
                    }}
                  />
                </div>
              </div>
            )}

            <div className={styles.row}>
              <Button disabled={submitting} type="submit">
                {texts.paymentForm.buttonPayLabel}
              </Button>
            </div>

            {logosOf3dsSystems.length > 0 ? (
              <div className={classNames(styles.row, styles.iconsContainer)}>
                {logosOf3dsSystems.map((logo) => {
                  return (
                    <div className={styles.iconWrapper} key={logo.name}>
                      <img
                        alt={logo.name}
                        className={styles.icon}
                        src={logo.src}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}

            {termsUrl ? (
              <div className={styles.row}>
                <div className={classNames(styles.stamp, styles.col)}>
                  {interpolate(texts.paymentForm.termsAgreement, {
                    link: (content) => (
                      <Link href={termsUrl} target="_blank">
                        {content}
                      </Link>
                    ),
                  })}
                </div>
              </div>
            ) : null}
          </form>
        );
      }}
    </Form>
  );
};

export default PaymentForm;
