import classNames from 'classnames';
import { h } from 'preact';

import * as styles from './styles.css';

export const PaymentStatus = (props) => {
  const { type } = props;

  switch (type) {
    case 'success': {
      const { children, subtitle = 'Успешно' } = props;

      return (
        <section className={classNames(styles.container, styles.isSuccess)}>
          <svg
            className={styles.successIcon}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12"
              cy="12"
              r="11.5"
              vectorEffect="non-scaling-stroke"
            />
            <path d="M6 13L10 17L19 8" vectorEffect="non-scaling-stroke" />
          </svg>

          <h3 className={styles.title}>{subtitle}</h3>

          {children}
        </section>
      );
    }

    case 'failure': {
      const {
        children,
        error = 'Что-то пошло не так.',
        subtitle = 'Ошибка',
      } = props;

      return (
        <section className={classNames(styles.container, styles.isFailure)}>
          <svg
            className={styles.failureIcon}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12"
              cy="12"
              r="11.5"
              vectorEffect="non-scaling-stroke"
            />
            <path d="M7 17L17 7zM17 17L7 7" vectorEffect="non-scaling-stroke" />
          </svg>

          <h3 className={styles.title}>{subtitle}</h3>

          <div className={styles.description}>{error}</div>

          {children}
        </section>
      );
    }

    case 'process':
    default: {
      const {
        children = 'Это займет совсем немного времени',
        subtitle = 'Происходит оплата...',
      } = props;

      return (
        <section className={styles.container}>
          <div className={styles.loaderContainer}>
            <svg
              className={styles.loader}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="11.5"
                vectorEffect="non-scaling-stroke"
              />
              <path
                d="M13 21C7.47715 21 3 16.5228 3 11"
                vectorEffect="non-scaling-stroke"
              />
            </svg>
          </div>

          <h3 className={styles.title}>{subtitle}</h3>

          <div className={styles.description}>{children}</div>
        </section>
      );
    }
  }
};
