import { h, render } from 'preact';
import { StoreContext } from 'storeon/preact';

import PaymentContainer from '~/containers/PaymentContainer';
import createStore from '~/createStore';
import payment from '~/models/payment';
import mergeObjects from '~/utils/mergeObjects';

class PaymentForm {
  constructor(publicToken, options = {}) {
    this.publicToken = publicToken;
    this.options = mergeObjects(
      {
        container: document.getElementById(
          `${process.env.PREFIX}-payment-form`,
        ),
      },
      options,
    );
  }

  onReady = () => null;

  onPaymentStart = () => null;
  onPaymentSuccess = () => null;
  onPaymentFail = () => null;

  isRendered = false;

  render(options = {}) {
    const { container, customerInteractionRedirect, isCvcMasked, texts } =
      mergeObjects(this.options, options);

    container.innerHTML = '';

    const store = createStore([payment]);

    render(
      <StoreContext.Provider value={store}>
        <PaymentContainer
          customerInteractionRedirect={customerInteractionRedirect}
          isCvcMasked={isCvcMasked}
          publicToken={this.publicToken}
          onReady={this.onReady}
          onPaymentStart={this.onPaymentStart}
          onPaymentSuccess={this.onPaymentSuccess}
          onPaymentFail={this.onPaymentFail}
          texts={texts}
        />
      </StoreContext.Provider>,
      container,
    );

    this.isRendered = true;
  }
}

window[`${process.env.PREFIX_CAPITALIZED}PaymentForm`] = PaymentForm;
