import { h } from 'preact';

import api from '~/api';
import { InputCvv } from '~/components/InputCvv';
import { hasMinDigits, isRequired } from '~/utils/validate';

import createTokenizer from './createTokenizer';

const CARD_ELEMENT_TYPE = 'cvv';

const { Consumer, path, Provider } = createTokenizer({
  provider: {
    createToken: ({ name, publicToken, value }) =>
      api.token.create({
        name,
        publicToken,
        type: CARD_ELEMENT_TYPE,
        value,
      }),
    render(props) {
      return <InputCvv {...props} withDeco={false} />;
    },
    validationRules: [
      isRequired(),
      hasMinDigits({
        digits: 3,
        message: 'INVALID_CVV',
      }),
    ],
  },
  slug: CARD_ELEMENT_TYPE,
});

export { Consumer as default, path, Provider };
