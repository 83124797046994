import classNames from 'classnames';
import { h } from 'preact';
import { forwardRef } from 'preact/compat';

import * as styles from './styles.css';

export const Link = forwardRef((props, ref) => {
  const { children, className, href, onClick, target, type, ...restProps } =
    props;

  const commonProps = {
    ...restProps,
    ref,
    className: classNames(styles.container, className),
  };

  if (typeof onClick === 'function' || type) {
    return (
      <button {...commonProps} type={type || 'button'} onClick={onClick}>
        {children}
      </button>
    );
  }

  const rel = target === '_blank' ? 'noopener noreferrer' : undefined;

  return (
    <a {...commonProps} rel={rel} href={href} target={target}>
      {children}
    </a>
  );
});
