const FULL_YEAR_MAX = 2099;
const FULL_YEAR_MIN = 2010;

export const isExpiryDate =
  ({ message = 'INVALID_EXPIRY_DATE' } = {}) =>
  ({ month, year } = {}) => {
    const fullYear = Number(`20${year < 10 ? `0${year}` : year}`);

    if (
      !month ||
      month < 1 ||
      month > 12 ||
      !fullYear ||
      fullYear < FULL_YEAR_MIN ||
      fullYear > FULL_YEAR_MAX
    ) {
      return message;
    }

    return undefined;
  };
