import { h } from 'preact';

import api from '~/api';
import { InputExpiryDate } from '~/components/InputExpiryDate';
import { isExpiryDate, isRequired } from '~/utils/validate';

import createTokenizer from './createTokenizer';

const CARD_ELEMENT_TYPE = 'expire_date';

const { Consumer, path, Provider } = createTokenizer({
  provider: {
    createToken: ({ name, publicToken, value }) =>
      api.token.create({
        name,
        publicToken,
        type: CARD_ELEMENT_TYPE,
        value,
      }),
    normalizeValue(value) {
      if (!value) {
        return undefined;
      }

      const [month = '', year = ''] = value.split('/');

      return {
        month: Number(month),
        year: Number(year),
      };
    },
    render(props) {
      return <InputExpiryDate {...props} withDeco={false} />;
    },
    validationRules: [isRequired(), isExpiryDate()],
  },
  slug: CARD_ELEMENT_TYPE,
});

export { Consumer as default, path, Provider };
